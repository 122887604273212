import styled from "styled-components";

// interface FlexI {
//   flex: number | "none" | "auto" | "initial";
// }

export const Flex = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;

  /* flex: ""; */
  /* flex-basis: ""; */
  /* flex-direction: ""; */
  /* flex-flow: ""; */
  /* flex-grow: ""; */
  /* flex-shrink: ""; */
  /* flex-wrap: ""; */
  /* order: ""; */

  /* justify-content: ""; */
  /* align-content: ""; */
  /* align-items: ""; */
  /* align-self: ""; */
  /* place-content: ""; */
  /* place-items: ""; */
  /* row-gap: ""; */
  /* column-gap: ""; */
  /* gap: ""; */
`;
