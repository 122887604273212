import React, { useEffect, useState } from "react";
import { useKeyPress } from "./utils/useKeyPress";
import { getRandomHexColor } from "./utils/getRandomHexColor";

import { Flex } from "components/atoms/Flex";

import "./App.css";
import { Container } from "components/atoms/Container";

const makeColorArr = () => [
  getRandomHexColor(),
  getRandomHexColor(),
  getRandomHexColor(),
  getRandomHexColor(),
  getRandomHexColor(),
];

function App() {
  const [colors, setColors] = useState(() => makeColorArr());
  const resetColors = () => setColors(makeColorArr());

  const spacePressed = useKeyPress(" ");

  useEffect(() => {
    if (spacePressed === true) resetColors();
  }, [spacePressed]);

  return (
    <Container>
      <Flex>
        <nav className="nav">
          <span className="nav-item">Dashboard</span>
          <span className="nav-item">Team</span>
          <span className="nav-item">Projects</span>
          <span className="nav-item">Calendar</span>
        </nav>

        <div className="colors">
          {colors.map((color, i) => (
            <div
              key={`${i},${color}`}
              className="colorBar"
              style={{ background: color }}
            >
              {color}
            </div>
          ))}
        </div>
        <nav className="nav">
          <span className="nav-item">Dashboard</span>
          <span className="nav-item">Team</span>
          <span className="nav-item">Projects</span>
          <span className="nav-item">Calendar</span>
        </nav>
      </Flex>
    </Container>
  );
}

export default App;
